// import { useEffect, useState } from "react";
import "./App.css";
import CreateNewTrip from "./components/CreateNewTrip";
import ListTrips from "./components/ListTrips";

function App() {
  return(
    <div>
      <ListTrips />
      <CreateNewTrip />
    </div>
  )
}

export default App;
