import { useState, useEffect } from "react";

export default function useCustomTrips() {
  const [trips, setTrips] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setTrips([
        {
          id: 1,
          name: "Hem (Framnäsgatan) till stan",
          stopID: "9021014002440000",
          direction: "Backa",
          requestUrl: "https://api.vasttrafik.se/bin/rest.exe/v2/trip?format=json&originId=9021014002440000&destId=9021014001760000&maxChanges=0"
        },
        {
          id: 1,
          name: "Pilbågsgatan till stan",
          stopID: "9021014002440000",
          direction: "Backa",
          requestUrl: "https://api.vasttrafik.se/bin/rest.exe/v2/trip?format=json&originId=9021014005280000&destId=9021014001760000&maxChanges=0"
        },
        {
          id: 2,
          name: "Centrum (Brunnsparken) till hem",
          stopID: "9021014001760000",
          direction: "Fredriksdal",
          requestUrl: "https://api.vasttrafik.se/bin/rest.exe/v2/trip?format=json&originId=9021014001760000&destId=9021014005280000&maxChanges=0"
        },
        {
          id: 3,
          name: "Backaplan (Hjalmar Brantingsplatsen) till hem",
          stopID: "9021014003180000",
          direction: "Fredriksdal",
          requestUrl: "https://api.vasttrafik.se/bin/rest.exe/v2/trip?format=json&originId=9021014003180000&destId=9021014005280000&maxChanges=0"
        },
      ]);
    }, 0); // artificially create am optional delay for "reading for remote db"
  }, []);

  return trips;
}
