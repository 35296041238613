import styled from "styled-components";

const Button = styled.button`
    background: #3261e3;
    border: 0;
    border-radius: 15px;
    color: #FFF;
    margin-left: 100px;
    padding: 10px;
`

export default Button;