import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Card from "./styledComponents/Card"

const BusNumber = styled.p`
  background: blue;
  color: white;
  height: 40px;
  width: 10px;
  float: left;
  padding: 10px;
  margin: 0 0 0 0;
  border-radius: 5px 0 0 5px;
  vertical-align: bottom;
`;

const RightSide = styled.p` 
margin: 0 0 0 30px;
height: 30px;
width: 50px;
color: black;
  ${props =>
    (props.time && css`
    background: lightgreen;
    border-radius: 0 5px 0 0;
    `)
    ||
    (props.track && css`
    background: pink;
    border-radius: 0 0 5px 0;
    `)
  }
`;

function TripDisplay(props) {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      let secondsDifference = Math.floor(
        (props.departureTime - new Date()) / 1000
      );

      if (secondsDifference <= 10) {
        setTimeLeft("nu");
        return;
      }

      const hours = Math.floor(secondsDifference / 60 / 60) % 60;
      const minutes = Math.floor(secondsDifference / 60) % 60;
      const seconds = Math.floor(secondsDifference % 60);

      if (hours > 0)
        setTimeLeft(
          `${getTime(hours)}:${getTime(minutes)}:${getTime(seconds)}`
        );
      else
        setTimeLeft(
          `${(`0` + minutes).slice(-2)}:${(`0` + seconds).slice(-2)}`
        );
    }, 1000);

    return () => clearInterval(interval);
  }, [props.departureTime]);

  const getTime = (value) => {
    return (`0` + value).slice(-2);
  };

  return (
    // <Card>
    //   {props.lineNumber} {timeLeft} {props.track}
    // </Card>
    <Card key={props.tripId} className="tripDisplay">
      <BusNumber className="busNumber">{props.lineNumber}</BusNumber>
      <RightSide className="departsIn" time>{timeLeft}</RightSide>
      <RightSide className="track" track>{props.track}</RightSide>
    </Card>
  );
}

export default TripDisplay;
