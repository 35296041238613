import styled, {css} from "styled-components";

const Card = styled.div`
  background: none;
  border: 0;
  border-radius: 5px;
  color: #fff;
  // width: 120px;
  float:left; 
  margin-top: 5px;
  padding-right: 5px;

  ${props => 
    props.parent && css`
    float: none;
    color: #000;
    margin: 10px;
    padding: 10px;
    background: orange;
    height: 150px;
    width: 87%;
  `};
`;

export default Card;