import { useEffect, useState } from "react";

export default function useVasttrafikToken() {
  const [tokenResult, setTokenResult] = useState({
    loaded: false,
    accessToken: null,
    error: null,
  });

  useEffect(() => {
    fetch(`https://api.vasttrafik.se:443/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "client_credentials",
        client_id: "rDKY9D0YgQPjE1_tMLefQ07xHaEa",
        client_secret: "fSPUT09jG3j04NDHe4Sv4mVpOJca",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Network response was not ok when fetching the token"
          );
        }
        return response.json();
      })
      .then(
        (token) => {
            console.log("setting token as true for " + token.access_token)
          setTokenResult((prevState) => ({
            ...prevState,
            accessToken: `${token.token_type} ${token.access_token}`,
            loaded: true,
          }));
        },
        (error) => {
          console.log(error);

          setTokenResult((prevState) => ({
            ...prevState,
            error: error,
          }));
        }
      );
  }, []);

  return tokenResult;
}
