import Destination from "./Destination";
import useCustomTrips from "../customHooks/useCustomTrips"
import useVasttrafikToken from "../customHooks/useVasttrafikToken"

function ListTrips() {
    const travels = useCustomTrips();
    const tokenResponse = useVasttrafikToken();
    
    if(travels.length === 0){
      return <div>Loading....</div>; 
    }
    else if (tokenResponse.error) {
      return <div>Error: {tokenResponse.error}</div>;
    } else if (!tokenResponse.loaded) {
      return <div>Loading....</div>;
    } else {
      // const now = new Date().toLocaleString("sv-SE").split(' '); // This date never changed...
      const fromStops = travels.map((dest) => (
        <Destination
          key={dest.id}
          name={dest.name}
          stopID={dest.stopID}
          direction={dest.direction}
          url={dest.requestUrl}
          accessToken={tokenResponse.accessToken}
          // date={now[0]}
          // time={now[1]}
        />
      ));
      
      return (
        <div>
          <h1>Next trip</h1>
          <div className="App">{fromStops}</div>
        </div>
      );
    }
  }
  
  export default ListTrips;