import { useEffect, useState } from "react";
import TripDisplay from "./TripDisplay";
import Card from "./styledComponents/Card";

function Destination(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [trips, setTrips] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLoaded(false);
      
      console.log(props.url);
      fetch(props.url,
        {
          headers: {
            Authorization: props.accessToken,
          },
        }
      )
        .then((response) => {
          if (!response.ok && response.status === 401) {
            throw new Error("Got unauthorized when fetching the departures");
          }
          if (!response.ok) {
            throw new Error(
              "Network response was not ok when fetching the departures"
            );
          }
          return response.json();
        })
        .then(
          (data) => {
            if (data.TripList.error) {
              console.log(`There was an error! ${data.TripList.error}`);
              setError(data.TripList.error);
              return;
            }
            setTrips(data.TripList.Trip);
            setIsLoaded(true);
          },
          (error) => {
            console.log(error);
            setError(error);
          }
        );
    }, 40000); // refetch all data at an interval doesn't seem to be working well

    return () => clearInterval(interval);
  }, [props.accessToken, props.date, props.stopID, props.time, props.url]);

  if (error) {
    return <p>Error: {error}</p>;
  } else if (!isLoaded) {
    return <p>Loading....</p>;
  } else {
    const relevantTrips = trips;
    let htmlResult = "";

    if (relevantTrips.length === 0)
      htmlResult = <p>Inga matchande resor</p>;
    else
      htmlResult = relevantTrips.map((trip) => {
        let date = trip.Leg.Origin.rtDate ? trip.Leg.Origin.rtDate : trip.Leg.Origin.date; // rtDate may disappear sometimes if bus is late apparently. It is late but has left?
        let time = trip.Leg.Origin.rtTime ? trip.Leg.Origin.rtTime : trip.Leg.Origin.time;  // rtTime may disappear sometimes if bus is late apparently. It is late but has left?
        let dateTime = new Date(`${date} ${time}`);
        
        return (
          <TripDisplay
            key={trip.Leg.journeyNumber}
            lineNumber={trip.Leg.sname}
            departureTime={dateTime}
            track={trip.Leg.Origin.track}
          />
        );
      });

    return (
      <Card key={props.id} className="destination" parent>
        <p>
          <b>{props.name}</b>
        </p>
        {htmlResult}
      </Card>
    );
  }
}

export default Destination;
